import { makeStyles } from '@material-ui/core';
import React from 'react';

import Logo from './assets/logo.svg';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 65,
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.img} src={Logo} />;
};

export default LogoFull;
