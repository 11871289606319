import type { TechRadarApi, TechRadarLoaderResponse } from "@backstage-community/plugin-tech-radar";

export class TechRadarFetcher implements TechRadarApi {
  async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {

    // TODO: `fetch` bonprix tech radar data from somewhere

    return {
      quadrants: [
        {
          id: "1",
          name: "Bottom right",
        },
        {
          id: "2",
          name: "Bottom left",
        },
        {
          id: "3",
          name: "Top left",
        },
        {
          id: "4",
          name: "Top right",
        },
      ],
      rings: [
        {
          id: "adopt",
          name: "ADOPT",
          color: "#93c47d",
        },
        {
          id: "trial",
          name: "TRIAL",
          color: "#93d2c2",
        },
        {
          id: "assess",
          name: "ASSESS",
          color: "#fbdb84",
        },
        {
          id: "hold",
          name: "HOLD",
          color: "#efafa9",
        },
      ],
      entries: [
        {
          id: "typescript",
          title: "TypeScript",
          description: "The de-facto standard for web development.",
          key: "typescript",
          url: "https://www.typescriptlang.org/",
          quadrant: "1",
          timeline: [
            {
              moved: 1,
              ringId: "adopt",
              date: new Date("2022-02-08"),
              description: "Long description for adopt",
            },
            {
              moved: 0,
              ringId: "trial",
              date: new Date("2022-02-06"),
              description: "Long description for trial",
            },
          ],
        },
        {
          id: "kotlin",
          title: "Kotlin",
          description: "Kotlin is a modern Language for JVM and Multiplattform development.",
          key: "kotlin",
          url: "https://kotlinlang.org/",
          quadrant: "1",
          timeline: [
            {
              moved: 0,
              ringId: "adopt",
              date: new Date("2024-05-16"),
              description: "Kotlin makes development more safe and fun.",
            },
          ],
        },
      ],
    }
  }
}