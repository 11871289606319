import React from 'react';
import { makeStyles } from '@material-ui/core';

import Logo from './assets/logo-icon.svg';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 30,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img className={classes.img} src={Logo} />;
};

export default LogoIcon;
