import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export { StudioIcon } from './components/StudioIcon';

export const studioPlugin = createPlugin({
  id: 'studio',
  routes: {
    root: rootRouteRef,
  },
});

export const StudioPage = studioPlugin.provide(
  createRoutableExtension({
    name: 'StudioPage',
    component: () => import('./components/Explorer').then(m => m.Explorer),
    mountPoint: rootRouteRef,
  }),
);
